import React from 'react';
import GetUsKnowSlider from './GetUsKnowSlider';
import Button from '../atoms/Button';
import { Trans, useTranslation } from 'react-i18next';

const GetUsKnow = ({ id = '' }) => {
  const { t } = useTranslation();
  return (
    <div className='p-3 mb-[8rem]' id={id}>
      <div className='md:w[50%] lg:w-[50%]  mx-auto'>
        <p className='font-bold text-3xl md:text-[3.5rem] xl:text-[3.5rem] leading-none py-10 tracking-wide'>
          <Trans t={t} shouldUnescape i18nKey="GetKnowUs">
            <span className='text-[#00807F] tracking-wide px-1'></span>
          </Trans>
        </p>
      </div>

      <GetUsKnowSlider />

      <div>
        <p className='text-[#8E9AA6] text-center pb-[14px]'>
          {t("Create a free account, complete your profile, and get matched with your dream job.")}
        </p>
        <Button title={t('View All')} className='bg-[#214E92] text-white' />
      </div>
    </div>
  );
};

export default GetUsKnow;
