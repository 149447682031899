import { ErrorMessage, Field, Form, Formik } from "formik";
import React from "react";
import Button from "../atoms/Button";
import { CONTACT_WHATSAPP_NUMBER } from "../../config";
import { useTranslation, Trans } from 'react-i18next';

import * as Yup from "yup";


const initialValues = {
  fullName: "",
  email: "",
  message: "",
};

const validationSchema = Yup.object({
  fullName: Yup.string().required("Name is required"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  message: Yup.string().required("Message is required"),
});

const GetInTouch = ({ id = "" }) => {
  const handleSubmit = (values, actions) => {
    const message = `Message from: ${values.fullName} (${values.email})\n${values.message}`
    let url = `https://api.whatsapp.com/send?phone=${CONTACT_WHATSAPP_NUMBER}`;
    url += `&text=${encodeURI(message)}`
    window.open(url)
    actions.setSubmitting(false);
  };
  const { t } = useTranslation();

  return (
    <div id={id} className="container mx-auto py-16 grid grid-cols-1 md:grid-cols-2">
      <div className="grid place-items-center h-full">
        <div>
          <Trans shouldUnescape t={t} i18nKey={"getInTouch"}>
          <p className="md:text-4xl font-bold text-[#24282D] text-3xl text-center md:text-left">
          </p>
          <p className="text-4xl pb-5 font-bold text-[#00807F] text-center md:text-left">
          </p>W
          </Trans>
        </div>
      </div>
      {/* FORM */}
      <div className="grid place-items-center h-full">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting }) => (
            <Form className="w-3/4">
              <div className="mb-6">
                <Field
                  type="text"
                  name="fullName"
                  className="form-control outline-0 block w-full px-6 py-3 text-sm font-normal text-gray-700 bg-white  border border-solid border-gray-300 rounded-[46px] "
                  id="name"
                  placeholder={t("Your Name")}
                />
                <ErrorMessage name="fullName" className="text-red-600 text-start" component="div" />
              </div>

              <div className="mb-6">
                <Field
                  type="email"
                  name="email"
                  className="form-control outline-0 block w-full px-6 py-3 text-sm font-normal text-gray-700 bg-white  border border-solid border-gray-300 rounded-[46px] "
                  id="email"
                  placeholder={t("Your Email")}
                />
                <ErrorMessage name="email" component="div" className="text-red-600 text-start" />
              </div>

              <div className="mb-6">
                <Field
                  as="textarea"
                  name="message"
                  className="form-control outline-0 block w-full px-6 py-3 text-sm font-normal text-gray-700 bg-white  border border-solid border-gray-300 rounded-[46px] "
                  id="message"
                  placeholder={t("Your Message...")}
                />
                <ErrorMessage className="text-red-600 text-start" name="message" component="div" />
              </div>



              <div className="flex justify-start ">
                <Button
                  type="submit"
                  title={t("Submit")}
                  className="bg-darkBlue text-white"
                  disabled={isSubmitting}
                />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default GetInTouch;
