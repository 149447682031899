import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import ClosebarIcon from '../../assets/svg/ClosebarIcon';
import MenubarIcon from '../../assets/svg/MenubarIcon';
import MobileScreen from './MobileScreen';
import GlobeIcon from '../../assets/svg/GlobeIcon';
import LanguageChange from '../../components/atoms/LanguageChange';
import { headerRoutes } from '../../routes/headerRoutes';
import LogoEn from '../../assets/svg/LogoEn';
import LogoAr from '../../assets/svg/LogoAr'

const PrimaryNavbar = () => {
    const [open, setOpen] = useState(false);
    const { i18n } = useTranslation();

    useEffect(() => {
        document.body.lang = i18n.language;

        if (i18n.language === 'en') {
            document.body.dir = 'ltr';
        } else {
            document.body.dir = 'rtl';
        }
    }, [i18n.language]);

    return (
        <nav className='w-full z-50  shadow-lg'>
            <div className='sm:px-2 container mx-auto py-7 px-4 md:px-20'>
                <div className=' relative flex justify-between items-center '>
                    <div>
                        <a href='/'>
                            {i18n.language === "ar" ? <LogoAr /> : <LogoEn />}
                        </a>
                    </div>

                    <div className='hidden md:flex list-none gap-x-6 text-slateGray font-medium text-sm'>
                        {headerRoutes?.map((item) => (
                            <li key={item?.id}>
                                <a href={item.path}>{i18n.language === 'en' ? item?.name : item?.name_ar}</a>
                            </li>
                        ))}
                    </div>

                    <div className=' flex items-center  gap-5'>
                        <button className='flex items-center gap-2' type='button'>
                            <GlobeIcon />
                            {/* <span className='flex items-center gap-1 font-semibold text-xs'>EN  <ArrowDown/></span>   */}
                            <LanguageChange />
                        </button>

                        <div className=' flex items-center md:hidden'>
                            <button
                                type='button'
                                onClick={() => setOpen(!open)}
                                className='inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white'>
                                <span className='sr-only'>Open main menu</span>
                                {open ? (
                                    <ClosebarIcon className='h-6 w-6' aria-hidden='true' />
                                ) : (
                                    <MenubarIcon className='h-6 w-6' aria-hidden='true' />
                                )}
                            </button>
                        </div>
                    </div>
                </div>

                {open && <MobileScreen setOpen={setOpen} />}
            </div>
        </nav>
    );
};

export default PrimaryNavbar;
