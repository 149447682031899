export const CONTACT_WHATSAPP_NUMBER = "+48509952312";
export const CONTACT_EMAIL = "info@opus.sa";
export const FACEBOOK_URL = "https://facebook.com";
export const INSTAGRAM_URL = "https://instagram.com";
export const TWITTER_URL = "https://x.com";
export const APPLE_STORE_URL = "https://www.apple.com/pl/app-store/";
export const GOOGLE_PLAY_URL = "https://play.google.com/store/games?hl=en&pli=1";
export const WEBAPP_URL = "https://app.dev.opus.sa/";

// Feature flags
export const FF_SHOW_COUNTER = false;
