/* eslint-disable react/require-default-props */
import PropTypes from "prop-types";

const Facebook = ({ height = 20, width = 20, className = "", color = "" }) => (
  <svg
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    className={className}
    fill="none"
    stroke={` ${color}`}
    strokeWidth="2"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14 7.90003H11.6556V6.50003C11.6556 5.77764 11.7114 5.32264 12.6951 5.32264H13.9375V3.09664C13.3329 3.03084 12.725 2.99864 12.1165 3.00004C10.3121 3.00004 8.99525 4.15994 8.99525 6.28933V7.90003H7V10.7L8.99525 10.6993V17H11.6556V10.6979L13.6947 10.6972L14 7.90003Z"
      fill="#8E9AA6"
    />
  </svg>
);

Facebook.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  className: PropTypes.string,
};

export default Facebook;
