/* eslint-disable react/require-default-props */
import PropTypes from "prop-types";

const Twitter = ({ height = 20, width = 20, className = "", color = "" }) => (
  <svg
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    className={className}
    fill="none"
    stroke={` ${color}`}
    strokeWidth="2"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19 3C18.298 3.4051 16.8895 3.95719 16.0615 4.11643C16.0372 4.12255 16.0174 4.13043 15.994 4.13655C15.2623 3.43485 14.2597 3 13.15 3C10.9135 3 9.1 4.76301 9.1 6.93725C9.1 7.05187 9.0901 7.26273 9.1 7.37473C6.0823 7.37473 3.7855 5.83832 2.1385 3.87495C1.9594 4.31242 1.8811 5.00362 1.8811 5.65283C1.8811 6.87863 2.8666 8.08256 4.4011 8.82889C4.1185 8.89976 3.8071 8.9505 3.4831 8.9505C2.9602 8.9505 2.4067 8.81664 1.9 8.41066C1.9 8.42554 1.9 8.43954 1.9 8.45528C1.9 10.1684 3.7702 11.3347 5.4334 11.6593C5.0959 11.8527 4.4155 11.8719 4.0834 11.8719C3.8494 11.8719 3.0214 11.7678 2.8 11.7276C3.2626 13.1319 4.9312 13.9211 6.5215 13.9491C5.2777 14.8975 4.4146 15.2492 1.8676 15.2492H1C2.6092 16.2519 4.6585 17 6.7123 17C13.3993 17 17.2 12.0452 17.2 7.37473C17.2 7.29948 17.1982 7.14199 17.1955 6.98363C17.1955 6.96788 17.2 6.953 17.2 6.93725C17.2 6.91363 17.1928 6.89088 17.1928 6.86726C17.1901 6.74827 17.1874 6.63715 17.1847 6.5794C17.8957 6.08068 18.5122 5.4586 19 4.74989C18.3475 5.03162 17.6473 5.22061 16.912 5.30636C17.6626 4.86888 18.7291 3.82595 19 3Z"
      fill="#8E9AA6"
    />
  </svg>
);

Twitter.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  className: PropTypes.string,
};

export default Twitter;
